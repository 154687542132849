export default function ({ app, $axios, store }) {
  $axios.onRequest((config) => {
    if (store.state.country) {
      config.headers.common['X-Tenant'] = store.state.country
      config.headers.common['X-Locale'] = store.state.locale
    }
    const token = app.$cookies.get('AuthTkn')

    // console.log('Making request to ' + config.url)
    // console.log(`AXIOS on Request: ${token}`)

    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`
    } else {
      delete config.headers.common.Authorization
    }
  })

  $axios.onResponse((response) => {
    // console.log(response)
  })

  $axios.onError((error) => {
    if (error.response && error.response.status === 401) {
      store.dispatch('auth/logout')
    }
    // console.log(error.response)
    return Promise.reject(error)
  })
}
