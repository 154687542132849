export default ({ app, store, isHMR }, inject) => {
  inject("loadGTM", () => {
    if (!process.browser || !store.state.country) {
      return;
    }

    const GTMRegions = {
      africa: 'GTM-WH8HBPV',
      americas: 'GTM-NKQ4K4M',
      asia: 'GTM-NNFG8C8',
      europe: 'GTM-5B6K6RC',
      oceania: 'GTM-KJKR73N'
    }

    const currentSiteRegion =
      (store.getters.getCurrentSite &&
        store.getters.getCurrentSite.country &&
        store.getters.getCurrentSite.country.region.toLowerCase()) ||
      null;

    if (!currentSiteRegion) {
      console.warn("[gtm] the current site contains an invalid region");
      return;
    }

    const currentGTM = GTMRegions[currentSiteRegion];

    if (!currentGTM) {
      console.warn("[gtm] empty gtm id");
      return;
    }

    // Initialize the data layer for Google Tag Manager (this should mandatory be done before the Cookie Solution is loaded)
    window.dataLayer = window.dataLayer || [];

    // eslint-disable-next-line no-inner-declarations
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };

    // Default consent mode is "denied" for both ads and analytics, but delay for 2 seconds until the Cookie Solution is loaded
    window.gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
      wait_for_update: 2000, // milliseconds
    });

    // Further redact your ads data (optional)
    window.gtag("set", "ads_data_redaction", true);

    // Send page view when GTAG it's loaded
    app.router.afterEach((to, from) => {
      if (window.gtag && !isHMR && to.path !== from.path) {
        window.gtag("config", currentGTM, {
          page_path: to.fullPath,
        });
      }
    });

    (function (w, d, s, l, i) {
      w[l] = w[l] || [];

      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });

      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l !== "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", currentGTM);
  });
};
